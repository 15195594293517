<template>
  <div class="maintenace-table">
    <ag-grid-vue
      style="width: 100%; height: 500px;"
      class="ag-theme-alpine"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :rowSelection="rowSelection"
      suppressDragLeaveHidesColumns
      @selection-changed="onSelectionChanged"
      suppressMovableColumns
      checkboxSelection="true"
      @sort-changed="onSortChange"
      :singleClickEdit="true"
      :stopEditingWhenCellsLoseFocus="true"
      @bodyScroll="hideEdit"
    >
      <!-- :setQuickFilter="updateSearchQuery" -->
    </ag-grid-vue>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import moment from "moment";
import { mapGetters } from "vuex";
import assetImg from "../../Table/components/assetImg.vue";
import baseUrl from "@/util/backend";
import { authHeader } from "@/store/actions";
import axios from "axios";

// NOTE for editing cell
import maintenanceStatusCard from "./CellFramework/MaintenanceStatusCard.vue";
import maintenanceUrgencyStatus from "./CellFramework/MaintenanceUrgencyStatus.vue";
import maintenanceStatusSelect from "./CellFramework/MaintenanceStatusSelect.vue";
import maintenanceStatusDropdown from "./CellFramework/MaintenanceStatusDropdown.vue";
import maintenanceDetail from "./CellFramework/MaintenanceDetail.vue";
import maintenanceUpperManage from "./CellFramework/MaintenanceUpperManage.vue";
import maintenanceManageOption from "./CellFramework/MaintenanceManageOption.vue";

//  Table Components

export default {
  emits: [
    "change-status",
    "cancel-action",
    "show-report-detail",
    "manage-selected",
  ],
  components: {
    AgGridVue,
    assetImg,
    maintenanceUrgencyStatus,
    maintenanceStatusCard,
    maintenanceStatusSelect,
    maintenanceStatusDropdown,
    maintenanceDetail,
    maintenanceUpperManage,
    maintenanceManageOption,
  },
  data() {
    return {
      // tableData: null,
      showDetail: false,
      columnDefs: null,
      gridApi: null,
      gridColumnApi: null,
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
    };
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    maintenanceRole: {
      type: String,
      default: "user",
    },
    rowSelection: { required: false, default: "multiple" },
    page: { type: Number },
    perPage: {
      type: Number,
      required: false,
      default: 10,
    },
    assetData: { default: () => [] },
    otherColumn: { default: () => [] },
  },
  beforeMount() {
    this.columnDefs = [
      {
        headerName: this.$t("count.order"),
        field: "index",
        // headerCheckboxSelection:
        //   this.rowSelection === "multiple" ? true : false,
        // headerCheckboxSelectionFilteredOnly: true,
        // checkboxSelection: this.rowSelection !== "none" ? true : false,
        maxWidth: 110,
        suppressSizeToFit: true,
        cellClass: "text-center",
      },
      {
        headerName: "Order Number",
        field: "maintain_ref",
        sortable: true,
        suppressSizeToFit: true,
      },

      {
        headerName: "Asset ID",
        field: "id_fixed_asset",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: "Asset Name",
        field: "asset_name",
        sortable: true,
        minWidth: 200,
      },
      // {
      //   headerName: this.$t("maintenance_report.category"),
      //   field: "type",
      //   sortable: true,
      //   minWidth: 200,
      //   cellRenderer: (params) => {
      //     return this.$t("maintenance_report." + params.value);
      //   },
      // },
      {
        headerName: this.$t("maintenance_report.urgency"),
        field: "priority",
        sortable: true,
        suppressSizeToFit: true,
        cellRenderer: "maintenanceUrgencyStatus",
      },
      {
        headerName: this.$t("asset.status"),
        field: "status",
        sortable: true,
        suppressSizeToFit: true,
        // cellRenderer:
        // (this.maintenanceRole !== "user" || this.maintenanceRole !== "admin") ||
        // (this.maintenanceRole === "low-technician" && this.$route.name === "MyMaintenanceReportList")
        //     ? "maintenanceStatusDropdown"
        //     : "maintenanceStatusCard",
        cellRenderer:
          this.maintenanceRole === "low-technician" && this.$route.name === "MyMaintenanceReportList"
            ? "maintenanceStatusCard"
            : (this.maintenanceRole === "high-technician" || this.maintenanceRole == "admin") && this.$route.name === "MyMaintenanceReportList"
            ? "maintenanceStatusDropdown"
            : this.maintenanceRole !== "user" 
              // this.maintenanceRole !== "admin"
            ? "maintenanceStatusDropdown"
            : "maintenanceStatusCard",

        ...(this.maintenanceRole === "low-technician" &&
        this.$route.name === "MyMaintenanceReportList"
          ? {
              cellClass: "overflow-visible",
              cellRendererParams: {
                onStatusChange: this.onStatusChange.bind(this),
                onManageChange: this.onManageChange.bind(this),
              },
              editable: false,
              cellRenderer: "maintenanceStatusCard",
            }
          : (this.maintenanceRole === "high-technician" || this.maintenanceRole === "admin") &&
            this.$route.name === "MyMaintenanceReportList"
          ? {
              cellClass: "overflow-visible",
              cellRendererParams: {
                onStatusChange: this.onStatusChange.bind(this),
                onManageChange: this.onManageChange.bind(this),
              },
              editable: (params) => {
                const nonEditableStatuses = [
                  "complete",
                  "cancel",
                  "reject",
                  "other",
                ];
                return !nonEditableStatuses.includes(params.data.status);
              },
              cellEditor: "maintenanceStatusSelect",
              cellEditorParams: {
                onStatusChange: this.onStatusChange.bind(this),
                onManageChange: this.onManageChange.bind(this),
              },
              cellEditorPopup: true,
              cellEditorPopupPosition: "under",
            }
          : this.maintenanceRole !== "user" &&
            // this.maintenanceRole !== "admin" &&
            this.$route.name !== "MyMaintenanceReportList"
          ? {
              cellClass: "overflow-visible",
              cellRendererParams: {
                onStatusChange: this.onStatusChange.bind(this),
                onManageChange: this.onManageChange.bind(this),
              },
              editable: (params) => {
                const nonEditableStatuses = [
                  "complete",
                  "cancel",
                  "reject",
                  "other",
                ];
                return !nonEditableStatuses.includes(params.data.status);
              },
              cellEditor: "maintenanceStatusSelect",
              cellEditorParams: {
                onStatusChange: this.onStatusChange.bind(this),
                onManageChange: this.onManageChange.bind(this),
              },
              cellEditorPopup: true,
              cellEditorPopupPosition: "under",
            }
          : {}),

        // ...(this.maintenanceRole !== "user" &&
        // this.maintenanceRole !== "admin" &&
        // this.$route.name !== "MyMaintenanceReportList"
        //   ? {
        //       cellClass: "overflow-visible",
        //       cellRendererParams: {
        //         onStatusChange: this.onStatusChange.bind(this),
        //       },
        //       // only allow editing if the status has options
        //       // editable: (params) => params.data.status.options !== null,
        //       editable: (params) => {
        //         const nonEditableStatuses = [
        //           "complete",
        //           "cancel",
        //           "reject",
        //           "other",
        //         ];
        //         return !nonEditableStatuses.includes(params.data.status);
        //       },
        //       // uses a custom Cell Editor
        //       cellEditor: "maintenanceStatusSelect",
        //       // provides params to the Cell Editor
        //       cellEditorParams: {
        //         onStatusChange: this.onStatusChange.bind(this),
        //       },
        //       // show this editor in a popup
        //       cellEditorPopup: true,
        //       // position the popup under the cell
        //       cellEditorPopupPosition: "under",
        //     }
        //   : {}),
      },
      {
        headerName: this.$t("maintenance_report.reportdate"),
        field: "request_at",
        sortable: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          return moment(params.value).format("DD/MM/YYYY - HH:mm น.");
        },
      },
      {
        headerName: this.$t("maintenance_report.technician-name"),
        field: "technician_name",
        sortable: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          return params.value ? params.value : "-";
        },
      },
      {
        headerName:
          this.$t("maintenance_report.cost") +
          " (" +
          this.$t("maintenance_report.baht") +
          ")",
        field: "total_cost",
        sortable: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          return params.value ? params.value : "-";
        },
      },
      {
        headerName: "Details",
        field: "id_maintain",
        sortable: true,
        suppressSizeToFit: true,
        cellRenderer: "maintenanceDetail",
      },
      {
        headerName: this.$t("maintenance_report.manage"),
        field: "id_maintain",
        sortable: true,
        suppressSizeToFit: true,
        cellRenderer: "maintenanceUpperManage",
        ...(this.maintenanceRole
          ? {
              cellClass: "overflow-visible",
              cellRendererParams: {
                onManageChange: this.onManageChange.bind(this),
              },
              // only allow editing if the status has options
              editable: (params) => {
                const noDownload = [
                  "complete",
                  "cancel",
                  "reject",
                  "in_progress",
                ];
                const noDownloadAdmin = [
                  "complete",
                  "cancel",
                  "reject",
                  "in_progress",
                  "pending",
                ];
                if (
                  params.data.status == "complete" ||
                  params.data.status == "cancel" ||
                  params.data.status == "reject"
                ) {
                  console.log(!noDownload.includes(params.data.status));

                  return !noDownload.includes(params.data.status);
                } else if (
                  (params.data.status == "in_progress" ||
                    params.data.status == "pending") &&
                  (this.maintenanceRole == "user" ||
                    this.maintenanceRole == "low-technician")
                ) {
                  console.log(!noDownloadAdmin.includes(params.data.status));

                  return !noDownloadAdmin.includes(params.data.status);
                } else {
                  return true;
                }
                // const nonEditableUser = [
                //   "complete",
                //   "cancel",
                //   "reject",
                //   "other",
                //   "in_progress",
                // ];
                // if (this.maintenanceRole === "user") {
                //   return !nonEditableUser.includes(params.data.status);
                // } else {
                // return true;
                // }
              },
              // uses a custom Cell Editor
              cellEditor: "maintenanceManageOption",
              // provides params to the Cell Editor
              cellEditorParams: {
                onManageChange: this.onManageChange.bind(this),
              },
              // show this editor in a popup
              cellEditorPopup: true,
              // position the popup under the cell
              cellEditorPopupPosition: "under",
            }
          : {}),
      },
    ];
  },
  async mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
  computed: {
    rowData() {
      return this.tableData;
      //   return [
      //     {
      //       index: 1,
      //       order_number: "RE1",
      //       asset_id: "VER-0001",
      //       type: "common",
      //       asset_name: "ไก่อ้วนใหญ่",
      //       urgency: "high",
      //       status: "pending",
      //       report_date: "12/12/2020 10:00",
      //       id_maintain: 1,
      //     },
      //     {
      //       index: 2,
      //       order_number: "RE2",
      //       asset_id: "VER-0002",
      //       type: "common",
      //       asset_name: "ไก่อ้วนเล็ก",
      //       urgency: "medium",
      //       status: "in_progress",
      //       report_date: "12/12/2020 10:00",
      //     },
      //     {
      //       index: 3,
      //       order_number: "RE3",
      //       asset_id: "VER-0003",
      //       type: "common",
      //       asset_name: "ไดโนเสา",
      //       urgency: "high",
      //       status: "complete",
      //       report_date: "12/12/2020 10:00",
      //     },
      //     {
      //       index: 4,
      //       order_number: "RE4",
      //       asset_id: "VER-0004",
      //       type: "common",
      //       asset_name: "กุ้งตาโปร",
      //       urgency: "low",
      //       status: "cancel",
      //       report_date: "12/12/2020 10:00",
      //     },
      //     {
      //       index: 5,
      //       order_number: "RE5",
      //       asset_id: "VER-0005",
      //       type: "common",
      //       asset_name: "ปูตาโปน",
      //       urgency: "high",
      //       status: "reject",
      //       report_date: "12/12/2020 10:00",
      //     },
      //     {
      //       index: 6,
      //       order_number: "RE6",
      //       asset_id: "VER-0006",
      //       type: "common",
      //       asset_name: "อ้วน",
      //       urgency: "",
      //       status: "other",
      //       report_date: "12/12/2020 10:00",
      //     },
      //   ];
    },
  },
  methods: {
    hideEdit() {
      this.gridApi.stopEditing();
    },
    onStatusChange({ oldStatus, newStatus, itemID }) {
      this.$emit("change-status", { oldStatus, newStatus, itemID });
    },
    //emit value from manage-option
    onManageChange({ manageSelected }) {
      this.$emit("manage-selected-table", {
        manageSelected,
      });
      //find value of maintain_ref in tableData that have same id_maintain as manageSelected.id
      const findFormId = this.tableData.find(
        (item) => item.id_maintain === manageSelected.id
      );
      this.$emit("find-form-id", {
        findFormId,
      });
      // console.log(findFormId);
    },
    onSortChange() {
      const colState = this.gridColumnApi.getColumnState();
      const sortState = colState
        .filter(function(s) {
          return s.sort != null;
        })
        .map(function(s) {
          return {
            colId: s.colId,
            sort: s.sort,
            sortIndex: s.sortIndex,
          };
        });
      // console.log(sortState);
    },

    onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
    },
    momentDay(date) {
      return moment(date).isValid() ? moment(date).format("DD/MM/YYYY") : null;
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) =>
        this.assetData.find(
          (asset) => asset.id_fixed_asset === node.data.id_fixed_asset
        )
      );
      return selectedData;
    },

    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => {
        return node.data;
      });
      this.$emit("selected-assets", selectedData);
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
  },
};
</script>

<style scoped lang="scss">
.maintenace-table {
  ::v-deep .ag-header-row-column {
    background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
    color: white;
  }
  ::v-deep .ag-row {
    z-index: 0;
    &.ag-row-focus {
      z-index: 1;
    }
  }

  ::v-deep .ag-popup-editor {
    background-color: #fff;
    padding: 0.5rem;
    border: 1px solid #d2d2d2;
    border-radius: 10px;
  }
}
</style>
